<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-row class="flex-column" no-gutters>
      <v-col>
        <div class="title">
          <h1 id="iiotAddEditNotificationTitle" data-cy="iiotAddEditNotificationTitle">
            {{
              isEdit()
                ? $t('notifications.addEdit.title', {
                    operation: $t('notifications.addEdit.update'),
                  })
                : $t('notifications.addEdit.title', { operation: $t('notifications.addEdit.add') })
            }}
          </h1>

          <v-divider />
        </div>
        <v-form
          id="iiotAddEditNotificationSubmitForm"
          ref="form"
          class="pa-0"
          data-cy="iiotAddEditNotificationSubmitForm"
          @submit.prevent="submitForm"
        >
          <v-col>
            <v-row>
              <v-col cols="12" lg="5" class="mr-12">
                <v-text-field
                  id="iiotAddEditNotificationHeaderText"
                  v-model="notificationData.headerText"
                  data-cy="iiotAddEditNotificationHeaderText"
                  :label="$t('notifications.addEdit.headerText')"
                  :rules="[
                    rules.required,
                    rules.no_control_characters,
                    rules.max_header_text_characters,
                    rules.header_string_contains_only_spaces,
                  ]"
                  :disabled="isEdit() && !canAccess('UI_NOTIFICATION:EDIT')"
                  class="mt-5"
                  validate-on-blur
                  autofocus
                />
                <v-textarea
                  id="iiotAddEditNotificationTextMessage"
                  v-model="notificationData.textMessage"
                  data-cy="iiotAddEditNotificationTextMessage"
                  :label="$t('notifications.addEdit.textMessage')"
                  :rules="[
                    rules.required,
                    rules.max_text_message_characters,
                    rules.message_string_contains_only_spaces,
                  ]"
                  :disabled="isEdit() && !canAccess('UI_NOTIFICATION:EDIT')"
                  class="mt-6"
                  outlined
                  validate-on-blur
                  @keyup.enter.native.stop=""
                />
              </v-col>
              <v-col cols="12" lg="5">
                <div class="mt-8 mb-4 dialogBackgroundDialog">
                  {{ $t('notifications.addEdit.backgroundImage') }}
                </div>
                <div id="iiotAddEditNotificationUploadedField" data-cy="iiotAddEditNotificationUploadedField">
                  <input
                    id="iiotAddEditNotificationImageField"
                    data-cy="iiotAddEditNotificationImageField"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    :disabled="isEdit() && !canAccess('UI_NOTIFICATION:EDIT')"
                    @change="changeNotificationBackground"
                  />
                  <span
                    v-if="notificationBackgroundSrc"
                    id="iiotAddEditNotificationBackgroundImage"
                    data-cy="iiotAddEditNotificationBackgroundImage"
                    :style="{ backgroundImage: 'url(' + notificationBackgroundImage + ')' }"
                  />
                  <div v-if="loadingImage" class="text-center">
                    <v-icon
                      id="iiotAddEditNotificationLoadingBackgroundImage"
                      data-cy="iiotAddEditNotificationLoadingBackgroundImage"
                      class="spinner white--text"
                    >
                      sync
                    </v-icon>
                    <br />
                    {{ $t('notifications.addEdit.loadingImage') }}
                  </div>
                  <div
                    v-if="!loadingImage && !notificationBackgroundSrc"
                    class="text-center white--text textInsideUploadArea"
                  >
                    <v-icon class="white--text"> arrow_upward </v-icon>
                    <br />
                    {{ $t('notifications.addEdit.uploadDragAndDropImage') }}
                  </div>
                </div>
                <v-checkbox
                  id="iiotAddEditNotificationShowBeforeLogin"
                  v-model="notificationData.showBeforeLogin"
                  data-cy="iiotAddEditNotificationShowBeforeLogin"
                  :label="$t('notifications.addEdit.showBeforeLogin')"
                  :disabled="isEdit() && !canAccess('UI_NOTIFICATION:EDIT')"
                  class="mt-12"
                />
                <v-checkbox
                  id="iiotAddEditNotificationShowActive"
                  v-model="notificationData.active"
                  data-cy="iiotAddEditNotificationShowActive"
                  :label="$t('notifications.addEdit.active')"
                  :disabled="isEdit() && !canAccess('UI_NOTIFICATION:EDIT')"
                  @click="changeActiveNotification()"
                />
              </v-col>
            </v-row>
            <v-col>
              <v-row>
                <v-col cols="12" class="pa-1">
                  <div>
                    <nerve-button
                      id="iiotAddEditNotificationCancelBtn"
                      :text="$t('baseForm.cancelBtn')"
                      data-cy="iiotAddEditNotificationCancelBtn"
                      class="mr-5 ml-0"
                      type-of-btn="cancel"
                      size="normal"
                      @click-event="$router.go(-1)"
                    />
                    <nerve-button
                      v-if="
                        (!isEdit() && !canAccess('UI_NOTIFICATION:EDIT')) ||
                        (isEdit() && canAccess('UI_NOTIFICATION:EDIT')) ||
                        (!isEdit() && canAccess('UI_NOTIFICATION:CREATE'))
                      "
                      id="iiotAddEditNotificationSaveUpdateButton"
                      :text="isEdit() ? $t('baseForm.updateBtn') : $t('baseForm.saveBtn')"
                      type-of-btn="action"
                      size="normal"
                      data-cy="iiotAddEditNotificationSaveUpdateButton"
                      type="submit"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { NerveButton } from 'nerve-ui-components';
import NotificationBackgroundMixin from '@/mixins/notification.mixin';
import {
  VALIDATION_REGEX,
  NOTIFICATION_HEADER_TEXT,
  NOTIFICATION_TEXT_MESSAGE,
  ALLOWED_EXTENSIONS,
  MAX_FILE_SIZE,
} from '@/constants';

export default {
  components: { NerveButton },
  mixins: [NotificationBackgroundMixin],
  data: () => ({
    notificationBackgroundSrc: null,
    notificationBackgroundFile: null,
    loadingImage: false,
    isShowBeforeLogin: false,
  }),

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('notifications.addEdit.required'),
        no_control_characters: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('notifications.addEdit.noControlCharacter'),
        max_header_text_characters: (value) =>
          value.length <= NOTIFICATION_HEADER_TEXT ||
          this.$t('notifications.addEdit.headerTextLengthMsg', {
            msgLength: NOTIFICATION_HEADER_TEXT,
          }),
        max_text_message_characters: (value) =>
          value.length <= NOTIFICATION_TEXT_MESSAGE ||
          this.$t('notifications.addEdit.textMessageLengthMsg', {
            msgLength: NOTIFICATION_TEXT_MESSAGE,
          }),
        header_string_contains_only_spaces: (value) =>
          !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES.test(value) ||
          this.$t('notifications.addEdit.headerTextOnlySpaces'),
        message_string_contains_only_spaces: (value) =>
          !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES.test(value) ||
          this.$t('notifications.addEdit.textMessageOnlySpaces'),
      };
    },
    notificationData() {
      return this.$store.getters['notifications/getNotificationData'];
    },
    notificationBackgroundImage() {
      return this.notificationBackgroundSrc[0] === '/'
        ? this.getNotificationBackgroundURL(this.notificationBackgroundSrc, this.isShowBeforeLogin)
        : this.notificationBackgroundSrc;
    },
  },

  async mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('notifications/reset_notification');
      if (this.isEdit()) {
        const id = window.location.pathname.split('/').reverse()[0];
        await this.$store.dispatch('notifications/get_notification', id);
        this.notificationBackgroundSrc = this.notificationData.backgroundImage;
        this.isShowBeforeLogin = this.notificationData.showBeforeLogin;
      }
    });
  },
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable consistent-return */
  methods: {
    _clearImage(event) {
      event.target.value = '';
      this.notificationBackgroundFile = null;
      this.notificationData.notificationBackgroundImage = '';
    },
    _getImageExt(event) {
      return event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
    },
    _showErrorToast(event, isError, text) {
      if (isError) {
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text,
          color: 'red',
          showClose: true,
        });
        return true;
      }
    },
    changeNotificationBackground(event) {
      this.notificationBackgroundSrc = null;
      if (
        !event.target.files.length ||
        this._showErrorToast(
          event,
          !ALLOWED_EXTENSIONS.includes(this._getImageExt(event)),
          'notifications.addEdit.wrongImgExtensionMsg',
        ) ||
        this._showErrorToast(
          event,
          event.target.files[0].size > MAX_FILE_SIZE,
          'notifications.addEdit.maxImgSizeExceededMsg',
        )
      ) {
        return this._clearImage(event);
      }
      // eslint-disable-next-line prefer-destructuring
      this.notificationBackgroundFile = event.target.files[0];
      this.loadingImage = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.notificationBackgroundSrc = e.target.result;
        this.loadingImage = false;
      };
      reader.readAsDataURL(this.notificationBackgroundFile);
    },
    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.notificationData) {
        if (this.notificationData._id) {
          await this.$store.dispatch('notifications/create_or_update_notification', {
            data: this.notificationData,
            image: this.notificationBackgroundFile,
          });
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'notifications.addEdit.successUpdate',
            color: 'success',
            showClose: true,
          });
        } else {
          await this.$store.dispatch('notifications/create_or_update_notification', {
            data: this.notificationData,
            image: this.notificationBackgroundFile,
          });
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'notifications.addEdit.successCreate',
            color: 'success',
            showClose: true,
          });
        }
      }
      this.$router.go(-1);
    },
    async changeActiveNotification() {
      this.notificationActiveDialogTitle = this.notificationData.active
        ? 'notifications.addEdit.selectActiveNotification'
        : 'notifications.addEdit.deselectActiveNotification';
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'notifications.addEdit.ActiveNotificationTitle',
        subTitle: this.notificationActiveDialogTitle,
        callback: async () => {
          this.$store.dispatch('utils/_api_request_handler/close_confirm');
        },
        cancelCallback: () => {
          this.notificationData.active = !this.notificationData.active;
        },
      });
    },
    isEdit() {
      return (
        !(window.location.pathname.split('/').reverse()[0] === 'new') &&
        !(window.location.pathname.split('/').reverse()[0] === 'notifications')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#iiotAddEditNotificationUploadedField,
#iiotAddEditNotificationImageField,
#iiotAddEditNotificationUploadedField > #iiotAddEditNotificationBackgroundImage {
  height: 180px;
  width: 500px;
}

#iiotAddEditNotificationUploadedField {
  background-color: #d2d2d2;
  align-items: center;
  justify-content: center;

  > #iiotAddEditNotificationBackgroundImage {
    background-repeat: no-repeat;
    pointer-events: none;
    background-size: 100% 100%;
  }

  > #iiotAddEditNotificationBackgroundImage,
  input {
    position: absolute;
  }

  input {
    cursor: pointer;
    opacity: 0;
  }

  > div {
    padding: 2em;
    word-wrap: break-word;

    .spinner {
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}

.dialogBackgroundDialog {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.textInsideUploadArea {
  font-size: 18px;
}
</style>
